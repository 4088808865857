import images from 'assets/images/images'
import { SearchOutlined, Settings, WifiNoConnection, WifiOutlined } from 'assets/svg'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { PAGE_IDS } from 'config/constants'
import { selectAppOnline, selectHasProductFinder } from 'redux/selector'
import DCAImage from 'components/DCAImage'
import { HeadlineDataType } from './Header'
import SearchBar from './SearchBar'
import UserProfileButton from './UserProfileButton'
import 'styles/components/breadcrumb.scss'
import 'styles/components/header.scss'

type HeaderProps = {
  pageId?: number
  headlineData?: HeadlineDataType
  onGoRootPage: () => void
  onGoProductFinder: () => void
}

const HeaderUI = (props: HeaderProps): React.ReactElement => {
  const isAppOnline = useSelector(selectAppOnline)
  const { pageId, headlineData, onGoRootPage, onGoProductFinder } = props
  const hasProductFinder = useSelector(selectHasProductFinder)
  const [searchBarVisible, setSearchBarVisible] = React.useState(false)

  const isBusinessEntry = pageId === PAGE_IDS.BUSINESS_UNIT_ENTRY
  const isShowProductFinder =
    pageId !== PAGE_IDS.PRODUCT_FINDER &&
    hasProductFinder &&
    headlineData?.headline === 'Professional'

  const hideSearchBar = () => {
    setSearchBarVisible(false)
  }

  const showSearchBar = () => {
    setSearchBarVisible(true)
  }

  const renderLogo = (): React.ReactElement => {
    return (
      <Col md='2'>
        {isBusinessEntry ? (
          <DCAImage id='onboarding-logo' className='ak-logo' alt='logo' src={images.logo} />
        ) : (
          <Link
            to='/business-entry'
            state={{
              pageId: PAGE_IDS.BUSINESS_UNIT_ENTRY,
            }}
          >
            <DCAImage id='onboarding-logo' className='ak-logo' alt='logo' src={images.logo} />
          </Link>
        )}
      </Col>
    )
  }

  const renderHeadline = (): React.ReactElement | null => {
    if (isBusinessEntry) {
      return <Col md='5' />
    }

    return (
      <Col md='5'>
        {headlineData && (
          <span onClick={onGoRootPage} className='headline-text'>
            {headlineData.headline}
          </span>
        )}

        {isShowProductFinder && (
          <span onClick={onGoProductFinder} className='product-finder-text'>
            PRODUCT FINDER
          </span>
        )}
      </Col>
    )
  }

  const renderRight = (): React.ReactElement => {
    return (
      <Col md={5}>
        <div style={{ display: 'flex', gap: 24, justifyContent: 'flex-end' }}>
          {searchBarVisible ? (
            <SearchBar onHide={hideSearchBar} />
          ) : (
            <div className='cursor-pointer' onClick={showSearchBar}>
              <SearchOutlined />
            </div>
          )}

          <div>{isAppOnline ? <WifiOutlined /> : <WifiNoConnection />}</div>
          {/* TODO: enable after release hot fix */}
          {/* <UserProfileButton /> */}
          <Link to='/settings'>
            <Settings />
          </Link>
        </div>
      </Col>
    )
  }

  return (
    <Container className='header-wrapper'>
      <Row className='align-items-center py-3'>
        {renderLogo()}

        {renderHeadline()}

        {renderRight()}
      </Row>
    </Container>
  )
}

export default HeaderUI

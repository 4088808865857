import { getProductListDbByRootLine } from 'database/queries/product'
import { getProductListByRootLine } from 'services'
import { useEffect, useState } from 'react'
import { Container, Dropdown, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  ProductGroupDataType,
  ProductListByRootLineResponseType,
  ProductListRootLineItemType,
} from 'types'
import { sortByStringPropertyAscending } from 'config/libs/helpers'
import { selectTranslations } from 'redux/selector'
import useDataFetcher from 'hooks/useDataFetcher'
import useNavigation from 'hooks/useNavigation'
import LoadingPage from 'features/Common/screens/LoadingPage'
import { useProductComparison } from 'features/Comparison/hooks/useProductComparison'
import { useHeaderContext } from 'components/Page/headerContext'
import ProductGroupListItem from './ProductGroupListItem'

type GroupListDropdownProps = {
  dropdownData: ProductGroupDataType[]
  productGroupId: number
  onProductListUpdated?: (productList: ProductListRootLineItemType[]) => void
}

const GroupListDropdown = ({
  dropdownData,
  productGroupId,
  onProductListUpdated,
}: GroupListDropdownProps) => {
  const translations = useSelector(selectTranslations)
  const [searchParams, setSearchParams] = useSearchParams()
  const { headerHeight, ribbonHeight } = useHeaderContext()

  const { onDynamicNavigate } = useNavigation()
  const { onAddOrRemoveProductCompare } = useProductComparison()
  const { fetchData } = useDataFetcher()

  const selectViaMachine = translations.select_via_machine || 'Selection via machine'
  const pleaseSelect = translations.please_select || 'Please Select'
  const noProduct = translations?.no_product || 'No product found'
  const priceRemarkTitle =
    translations.price_remark ?? '*recommended retail price. Prices by the dealer may vary.'

  const sortedDropdownData = sortByStringPropertyAscending<ProductGroupDataType>(
    dropdownData,
    'name',
  )
  const firstDropdownData = sortedDropdownData.filter((group) => group.parentId === productGroupId)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [groupData, setGroupData] = useState<ProductGroupDataType[][]>([firstDropdownData])
  const [selectedGroup, setSelectedGroup] = useState<ProductGroupDataType[]>([])
  const [productList, setProductList] = useState<ProductListRootLineItemType[]>()
  const priceExist = productList?.find((product) => product.priceNetFormatted !== null)

  const groupId = searchParams.get('selectedGroup')

  /**
   * Handle Initialize page with selected group params from URL
   */
  useEffect(() => {
    const initializePage = () => {
      if (!groupId) return

      const groupIds = parseGroupIds(groupId)
      if (!groupIds.length) return

      setIsLoading(true)
      processGroupIds(groupIds)
      setSelectedGroup(getSelectedGroups(groupIds) as ProductGroupDataType[])
      fetchProductListByRootLine(groupIds)
    }

    const parseGroupIds = (groupId: string) => {
      try {
        return JSON.parse(groupId) as number[]
      } catch (error) {
        console.error('Error parsing groupId', error)
        return []
      }
    }

    const processGroupIds = (groupIds: number[]) => {
      const newGroupIds = groupIds.slice(1)
      newGroupIds.forEach((id) => filterDropdownData(id))
    }

    const getSelectedGroups = (groupIds: number[]) => {
      return groupIds
        .map((id) => sortedDropdownData.find((group) => group.id === id))
        .filter((group) => !!group)
    }

    try {
      initializePage()
    } catch (error) {
      console.error('init page with group selected error', error)
      setIsLoading(false)
    }
  }, [groupId])

  const fetchProductListByRootLine = async (productGroupIds: number[]) => {
    try {
      const productList = await fetchData<number[], ProductListByRootLineResponseType>(
        getProductListByRootLine,
        getProductListDbByRootLine,
        productGroupIds,
        productGroupIds,
      )
      if (productList && productList.data) {
        setProductList(productList.data)
        if (onProductListUpdated) {
          onProductListUpdated(productList.data)
        }
      }
    } catch (error) {
      console.error('fetchProductList in GroupListDropdown error', error)
    } finally {
      setIsLoading(false)
    }
  }

  const filterDropdownData = (parentId: number | undefined) => {
    if (!parentId) return

    const filteredData = sortedDropdownData.filter((group) => group.parentId === parentId)

    const isDataExist = groupData.some((data) =>
      data.some((group) => filteredData.some((filteredGroup) => filteredGroup.id === group.id)),
    )
    if (isDataExist) return

    if (filteredData.length > 0) {
      setGroupData((prevGroupData) => [...prevGroupData, filteredData])
    }
  }

  const handleGroupSelect = (eventKey: string | null, index: number) => {
    if (!eventKey) return

    const selectedGroupItem = sortedDropdownData.find((group) => group.id === Number(eventKey))
    if (!selectedGroupItem) return

    setIsLoading(true)
    const newGroupData = [...groupData.slice(0, index + 1)]
    const newSelectedGroup = [...selectedGroup.slice(0, index + 1)]
    if (newSelectedGroup[index]) {
      newSelectedGroup[index] = selectedGroupItem
    } else {
      newSelectedGroup.push(selectedGroupItem)
    }
    setGroupData(newGroupData)
    setSelectedGroup(newSelectedGroup)
    filterDropdownData(selectedGroupItem.id)

    /** Handle data product list by root line */
    const groupIds = [productGroupId, ...newSelectedGroup.map((group) => group.id)]
    fetchProductListByRootLine(groupIds)
    setSearchParams({ selectedGroup: JSON.stringify(groupIds) })
  }

  const onGoNextPage = (nextPageId: number) => {
    if (!nextPageId) return

    onDynamicNavigate(nextPageId)
  }

  const onAddProductToComparison = (
    event: React.MouseEvent<HTMLDivElement>,
    productData: ProductListRootLineItemType,
  ) => {
    event.stopPropagation()
    onAddOrRemoveProductCompare(productData)
  }

  const renderDropdowns = () => {
    if (!groupData.length) return null

    return groupData.map((data, index) => {
      return (
        <Dropdown
          key={data[0].id}
          className='group-list-dropdown mt-2'
          onSelect={(eventKey) => handleGroupSelect(eventKey, index)}
        >
          <Dropdown.Toggle id={`group-list-dropdown-${index}`} className='w-100'>
            {selectedGroup[index]?.name ?? pleaseSelect}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {data.map((group) => {
              return (
                <Dropdown.Item key={group.id} eventKey={group.id.toString()}>
                  {group.name}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      )
    })
  }

  const renderProductList = () => {
    if (isLoading) return <LoadingPage />

    if (!productList) return null

    if (productList.length === 0) return <h5>{noProduct}</h5>

    return (
      <Row>
        {productList
          .filter((product) => !!product)
          .map((product) => (
            <ProductGroupListItem
              key={product.id}
              product={product}
              onGoNextPage={onGoNextPage}
              onAddProductToComparison={onAddProductToComparison}
            />
          ))}
      </Row>
    )
  }

  return (
    <Container className='mt-4 group-list-dropdown'>
      <Row>
        <h5 className='ak-bold'>{selectViaMachine}</h5>
        <div className='dropdown-container' style={{ top: headerHeight + ribbonHeight }}>
          {renderDropdowns()}
        </div>

        <div className='mt-4'>{renderProductList()}</div>
      </Row>
      {priceExist && priceRemarkTitle}
    </Container>
  )
}

export default GroupListDropdown
